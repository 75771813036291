import React, {useState, useRef, useEffect} from 'react';
import {CircularProgress} from '@mui/material';
import noImage from 'images/no-image.png';

const ImageWithSkeleton = (props) => {
    const {
        alignItems,
        className,
        height,
        width,
        display,
        justifyContent,
        src,
        alt,
        style
    } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [fallback, setFallback] = useState(false);
    const [parentSize, setParentSize] = useState(Math.max(height, width) || 0);
    const parentRef = useRef(null);

    useEffect(() => {
        //track parent-dimensions to set right size on CircularProgress
        const {
            clientWidth,
            clientHeight
        } = parentRef.current;
        setParentSize(Math.max(clientWidth, clientHeight));
    }, [parentRef.current]);

    const onLoadedImg = (e) => {
        setIsLoaded(true);
    };

    const onError = (eventError) => {
        setFallback(noImage); //activate this if you don't care about 404 images
    };

    const size = parentSize / 2.5;
    return (
        <div ref={parentRef} style={{
            height,
            width,
            display,
            justifyContent,
            alignItems
        }}>
            {!isLoaded && <div style={{
                height: 'auto',
                width: 'auto',
                position: 'absolute',
                transform: 'translate(65%, 55%)',
                overflow: 'hidden',
                margin: 'auto',
                zIndex: 1
            }}><CircularProgress
                size={size + 'px'}
                className={'actionLoading'}/></div>}
            <img
                style={{
                    display: isLoaded ? 'none' : 'block',
                    ...style
                }}
                src={noImage}
                alt={alt || ''}/>
            <img
                className={className}
                style={{
                    display: isLoaded ? 'block' : 'none',
                    ...style
                }}
                src={fallback || src || noImage}
                alt={alt || ''}
                onLoad={onLoadedImg}
                onError={onError}/>
        </div>);
};

export default ImageWithSkeleton;