import {showError} from 'utils/display';
import {storageSetItem} from 'utils/storage';

const ApiUrl = window.location.origin;

class ajaxHandlePool {
    constructor() {
        this.listOfAjax = [];
    }

    getListOfAjax() {
        return this.listOfAjax;
    }

    addNewAjax(ajaxHandle) {
        this.listOfAjax.push(ajaxHandle);
    }

    abortAjax(endPointUrl) {
        if (!endPointUrl.includes('component')) {
            const foundEPU = this.listOfAjax.findIndex(el => this.UrlPath(el.EPURL) === this.UrlPath(endPointUrl));
            if (foundEPU > -1) {
                this.listOfAjax[foundEPU].abortAjax();
                showError(this.listOfAjax, endPointUrl, foundEPU);
                this.listOfAjax.splice(foundEPU, 1);
            }
        }
    }

    UrlPath(EP) {
        return EP.split('?')[0];
    }
}

const listOfAjax = new ajaxHandlePool();

class ajaxHandle {
    constructor(endPoint, abortController) {
        this.EPURL = endPoint;
        this.abort = abortController;
        this.id = Math.random().toString(36).substring(2, length + 2);
        listOfAjax.addNewAjax(this);
    }

    getEndPoint() {
        return this.EPURL;
    }

    getId() {
        return this.id;
    }

    getAbortController() {
        return this.abort;
    }

    abortAjax() {
        this.abort.abort();
    }
}

/**
 *
 * @param EPUrl
 * @param callBack
 * @param limit
 * @param page
 * @param body
 * @param method
 * @param isDownloadable
 * @param force
 * @returns {Promise<{error: string}>}
 */
export async function ajaxRequestStream(EPUrl, callBack, limit = 5, page = 0, body = null, method = 'GET', isDownloadable = false, force = false) {
    //avoid AbortController for forced requests
    if (!force) {
        listOfAjax.abortAjax(EPUrl);
    }
    const abortController = new AbortController();
    const API_BASE = (process.env.NODE_ENV === 'production')
        ? ''
        : process.env.REACT_APP_DEVELOP_BACKEND_HOST;
    let URL = API_BASE + EPUrl;
    new ajaxHandle(EPUrl, abortController);

    const parameter = {
        method: method,
        signal: abortController.signal,
        credentials: (process.env.NODE_ENV === 'production')
            ? undefined
            : 'include'
    };

    let abortError = false;
    const response = await fetch(URL, parameter)
        .catch((err) => {
            if (err?.name === 'AbortError') {
                abortError = true;
            }
            return false;
        });
    if (!response) {
        if (!abortError) {
            callBack({error: 'Network Error is occurred!'});
        }
        return {error: 'Network Error is occurred!'};
    }
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    let bytesReceived = 0;
    let failedChunk = '';
    let cnt = 0;
    while (true) {
        let {
            value,
            done
        } = await reader.read();
        if (done) {
            break;
        }
        bytesReceived += value.length;
        if (failedChunk !== '') {
            value = failedChunk + value;
            failedChunk = '';
        }
        const valuesObj = value.split('\r\n')
            .map((el) => {
                if (el !== '') {
                    try {
                        let item = JSON.parse(el);
                        item.id = item.date ? item.date : cnt;
                        cnt++;
                        return item;
                    }
                    catch (ex) {
                        failedChunk = el;
                        showError(el, ex, value, 'error');
                    }
                }
                return false;
            })
            .filter((e) => (!!e));

        if (valuesObj?.[0]?.message === 'ok') {
            for (const el of valuesObj) {
                try {
                    await callBack(el);
                }
                catch (ex) {
                    // toast.error('Callback Error:', ex);
                }
            }
        }
        else {
            try {
                await callBack(valuesObj);
            }
            catch (ex) {
                // toast.error('Callback Error:', ex);
            }
        }
        /**/
    }
    await callBack('done');
}

/**
 *
 * @param EPUrl
 * @param body
 * @param method
 * @param isDownloadable
 * @param header
 * @param force
 * @returns {Promise<unknown>}
 */
export function ajaxRequest(EPUrl, body = null, method = 'GET', isDownloadable = false, header = null, force = false) {
    //avoid AbortController for forced requests
    if (!force) {
        listOfAjax.abortAjax(EPUrl);
    }
    const abortController = new AbortController();
    const API_BASE = (process.env.NODE_ENV === 'production')
        ? ''
        : process.env.REACT_APP_DEVELOP_BACKEND_HOST;
    let URL = API_BASE + EPUrl;
    new ajaxHandle(EPUrl, abortController);

    const parameter = {
        method: method,
        signal: abortController.signal,
        credentials: (process.env.NODE_ENV === 'production')
            ? undefined
            : 'include'
    };

    if (method === 'POST' && body !== null) {
        if (typeof body === 'object') {
            parameter.body = JSON.stringify(body);
        }
        else {
            parameter.body = body;
        }

        parameter.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header
        };
    }

    return new Promise(function (resolve, reject) {
        fetch(URL, parameter)
            .then(async(response) => {
                if (isDownloadable && response.status === 200) {
                    return response.blob();
                }
                if (response.redirected) {
                    if (EPUrl !== '/isLoggedIn') {
                        //test if user is loggedIn
                        const isSessionLoggedIn = await isLoggedIn();
                        if (!isSessionLoggedIn) {
                            // ... if not, make a redirect to login page
                            window.location.replace(window.location.origin);
                            return;
                        }
                    }
                    return {error: 'Network Error is occurred!'};
                }
                return response.json();
            })
            .then(resolve)
            .catch(function (err) {
                if (err?.name === 'AbortError') {
                    resolve({error: 'AbortError'});
                    return;
                }
                return resolve({error: 'Network Error is occurred!'});
            });
    });
}

export function getEndPointUrl() {
    return ApiUrl;
}

export async function listUsers() {
    return await ajaxRequest('/users');
}

export async function isLoggedIn() {
    let isLogin = await ajaxRequest('/isLoggedIn', null, 'POST');
    return isLogin.message === 'ok';
}

export async function listNavigation() {
    let navigations = await ajaxRequest('/component?c=navigator');
    storageSetItem('navigations', JSON.stringify(navigations));
    return navigations;
}

export async function getNotification() {
    let notifications = await ajaxRequest('/lastNotifications');
    return notifications;
}

export async function loginBackend(user, pass) {
    return ajaxRequest('/login', {
        email: user,
        password: btoa(pass)
    }, 'POST');
}

export async function loginSocialBackend(infoObj) {
    const tokenType = infoObj.microsoft.tokenType;
    const accessToken = infoObj.microsoft.idToken;
    return ajaxRequest('/socialLogin', infoObj.microsoft, 'POST', false, {'Authorization': tokenType + ' ' + accessToken});
}

export async function logoutBackend() {
    return ajaxRequest('/logout');
}

export async function listReports() {
    return await ajaxRequest('/reports');
}

export async function listLastReports() {
    return await ajaxRequest('/lastReports');
}

export async function listServers() {
    return await ajaxRequest('/servers');
}

///////////forgot password
export async function resetPass(email) {
    return await ajaxRequest('/recoverPassword?email=' + email, null, 'POST');
}

export async function getResetPassWordTokenValidate(token) {
    return await ajaxRequest('/newPassword?token=' + token);
}

export async function newPass(password, token) {
    return await ajaxRequest('/newPassword?token=' + token + '&password=' + password, null, 'POST');
}

function arrayToUrl(param) {
    return Object.entries(param)
        .map(e => e.join('=')).join('&');
}

function stripHTML(s) {
    let div = document.createElement('div');
    div.innerHTML = s;
    let scripts = div.getElementsByTagName('script');
    let i = scripts.length;
    while (i--) {
        scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerText;
}
