import React, {Component, Fragment} from 'react';
import {CircularProgress, Tooltip} from '@mui/material';
import './style.scss';
import {Edit, TrendingUp, Tune} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreIcon from '@mui/icons-material/More';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/GetApp';
import {Link} from 'react-router-dom';
import {ajaxRequest} from 'data_access/data';
import {toast} from 'react-toastify';
import {urlBuilderForAction} from 'utils/display';
import {showNetworkError} from 'utils/display';

export default class ActionIcons extends Component {
    state = {
        item: [],
        event: null,
        isHidden: false,
        href: '',
        row: [],
        loading: false,
        hover: false,
        ...this.props
    };

    async componentDidMount() {
        this.setState({
            isHidden: this.props.hidden,
            row: this.props.row,
            headerParam: this.props.headerParam,
            basicData: this.props.basicData,
            href: urlBuilderForAction(this.state.basicData, this.state.row, this.state.headerParam, this.props.icon === 'download')
        });
    }

    onClick = (event) => {
        event.preventDefault();
        return this.onClickCallBack(this.props.isDownloadable);
    };
    // Ajax callback
    onClickCallBack = (isDownloadable) => {
        this.setState({loading: true}, async() => {
            const href = this.state.href;

            if (isDownloadable) {
                const data = await ajaxRequest(href, null, this.props.method, isDownloadable);
                if (!data.error) {
                    let typeFile = {type: 'text/csv'};
                    if (this.state.row.name) {
                        typeFile = {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'};
                    }
                    const csvFile = new Blob([data], typeFile);
                    // Download link
                    const downloadLink = document.createElement('a');
                    // File name
                    downloadLink.download = this.state.row.name;
                    // We have to create a link to the file
                    downloadLink.href = window.URL.createObjectURL(csvFile);
                    downloadLink.click();
                }
                else {
                    if (data.error !== 'AbortError') {
                        toast.error(data.error);
                    }

                }
                this.setState({loading: false});
                return;
            }
            const data = await ajaxRequest(href, null, this.props.method);

            if (!data.error) {
                toast.success(data.message);
                if (data.data) {
                    const item = this.state.row;
                    item.hidden = data.data.isHidden;

                    const element = document.querySelector('.cell-id-' + item.id);
                    if (item.hidden) {
                        element?.classList.add('grid-cell-hide');
                    }
                    else {
                        element?.classList.remove('grid-cell-hide');
                    }

                    this.setState({
                        isHidden: data.data.isHidden,
                        row: item,
                        href: urlBuilderForAction(this.state.basicData, item, this.state.headerParam)
                    });
                }
            }
            else if (data.error
                && data.error !== 'AbortError') {
                showNetworkError(this, data.error);
            }

            this.setState({
                loading: false,
                hover: false
            });
        });
    };

    getVisibleIcon = (state, bgColor) => {
        // eslint-disable-next-line default-case
        switch (state) {
            case 1:
                return <VisibilityIcon style={{backgroundColor: bgColor}}
                                       fontSize="small"/>;
            case 2:
                return <VisibilityOffIcon style={{backgroundColor: bgColor}}
                                          fontSize="small"/>;
        }
    };

    render() {
        let icon;
        let bgColor = '';
        const UNSET = 'unset';
        const col1 = UNSET;//'#1DAEFF';
        const col2 = UNSET;//'#2FD18C';
        const col3 = UNSET;//'#707070';

        //set Icons and color
        switch (this.props.icon) {
            case 'edit' :
                bgColor = this.props.iconColor ? this.props.iconColor : col1;
                icon = <Edit style={{
                    backgroundColor: bgColor,
                    color: '#ffffff'
                }} fontSize="small"/>;
                break;
            case 'delete' :
                bgColor = this.props.iconColor ? this.props.iconColor : col2;
                icon = <DeleteIcon style={{backgroundColor: bgColor}}
                                   fontSize="small"/>;
                break;
            case 'show-hide' :
            case 'show' :
                if (this.state.isHidden) {
                    if (!this.state.hover) {
                        bgColor = this.props.iconColor ? this.props.iconColor : col3;
                        icon = this.getVisibleIcon(2, bgColor);
                    }
                    else {
                        bgColor = this.props.iconColor ? this.props.iconColor : col2;
                        icon = this.getVisibleIcon(1, bgColor);
                    }
                    break;
                }
                else {
                    if (!this.state.hover) {
                        bgColor = this.props.iconColor ? this.props.iconColor : col2;
                        icon = this.getVisibleIcon(1, bgColor);
                    }
                    else {
                        bgColor = this.props.iconColor ? this.props.iconColor : col3;
                        icon = this.getVisibleIcon(2, bgColor);
                    }
                    break;
                }
            case 'info' :
                bgColor = this.props.iconColor ? this.props.iconColor : UNSET;
                icon = <MoreHorizIcon style={{backgroundColor: bgColor}}
                                      fontSize="small"/>;
                break;
            case 'more' :
                bgColor = this.props.iconColor ? this.props.iconColor : UNSET;
                icon = <MoreIcon style={{backgroundColor: bgColor}}
                                 fontSize="small"/>;
                break;
            case 'download' :
                bgColor = this.props.iconColor ? this.props.iconColor : col1;
                icon = <DownloadIcon style={{backgroundColor: bgColor}}
                                     fontSize="small"/>;
                break;
            case 'graph':
                bgColor = this.props.iconColor ? this.props.iconColor : UNSET;
                icon = <TrendingUp style={{backgroundColor: bgColor}}
                                   fontSize="small"/>;
                break;
            case 'variation':
                bgColor = this.props.iconColor ? this.props.iconColor : UNSET;
                icon =
                    <Tune style={{backgroundColor: bgColor}} fontSize="small"/>;
                break;
            default:
                bgColor = this.props.iconColor ? this.props.iconColor : UNSET;
                icon = <MoreHorizIcon style={{backgroundColor: bgColor}}
                                      fontSize="small"/>;
                break;
        }

        return (
            this.props.ajax ?
                this.state.loading
                    ? <CircularProgress size={'15px'}
                                        className={'actionLoading'}/>
                    : <Link to={'#'} key={Date.now()}
                            onClick={this.onClick}
                            variant="contained"
                            style={{backgroundColor: bgColor}}
                            className={this.props.className + ' actionIcon ' + this.props.icon}
                            color={bgColor} size="small" aria-label="edit">
                        {
                            this.props.tooltip
                                ? <Tooltip arrow
                                           title={this.props.tooltip}>{icon}
                                </Tooltip>
                                : <Fragment>{icon}</Fragment>
                        }
                    </Link>
                : <Link target={this.props.target} to={this.state.href}
                        variant="contained"
                        style={{backgroundColor: bgColor}}
                        className={this.props.className + ' actionIcon ' + this.props.icon}
                        theme size="small" aria-label="edit">
                    {
                        this.props.tooltip
                            ? <Tooltip arrow title={this.props.tooltip}>
                                {icon}
                            </Tooltip>
                            : <Fragment>
                                {icon}
                            </Fragment>
                    }
                </Link>
        );
    }
}
