import React from 'react';
import iconHome from 'images/icons/sidebar/trp/icon-home.svg';
import iconPages from 'images/icons/sidebar/trp/icon-pages.svg';
import iconProxies from 'images/icons/sidebar/trp/icon-proxies.svg';
import iconUsers from 'images/icons/sidebar/trp/icon-users.svg';
import iconServers from 'images/icons/sidebar/trp/icon-servers.svg';
import iconOrders from 'images/icons/sidebar/trp/icon-orders.svg';
import iconResults from 'images/icons/sidebar/trp/icon-results.svg';
import iconReports from 'images/icons/sidebar/trp/icon-reports.svg';
import iconObserver from 'images/icons/frontend/pmon-icon-menu-observer.svg';
import iconProduct from 'images/icons/sidebar/trp/icon-product.svg';
import GridBar from '../components/GridBar';
import Chip from '@mui/material/Chip';
import Moment from 'moment';
import {toast} from 'react-toastify';
import noImageSmall from 'images/no-image-small.png';
import noImage from 'images/no-image.png';
import ActionIcons from 'components/ActionIcon';
import Tooltip from '@mui/material/Tooltip';
import './css/tooltip.scss';
import HtmlTooltip from '../components/HtmlTooltip';
import SwitchComponent from '../components/Switch';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ImageWithSkeleton from 'components/ImageWithSkeleton';

const debugMode = localStorage.getItem('debug')
    ? localStorage.getItem('debug')
    : false;

export function getValuesFromArray(arr, field = 'title', isStr = false) {
    const tmp = [];
    let separator, starter = '';

    if (isStr) {
        separator = '","';
        starter = '"';
    }

    if (arr) {
        for (const i of arr) {
            tmp.push(i[field]);
        }

        if (tmp.length > 0) {
            return starter + tmp.join(separator) + starter;
        }
        else {
            return '';
        }
    }
    else {
        return;
    }
}

export function setAttributeinArray(arr, attName, val) {
    arr.forEach(function (element) {
        if (!element[attName.toLowerCase()]) {
            element[attName.toLowerCase()] = val;
        }
    });
    return arr;
}

export function checkData(data) {
    if (data.error) {
        showNetworkError(this, data.error);
        return false;
    }
    else {
        return true;
    }
}

export function urlBuilderForAction(basicData, row, headerParam, isDownloadable) {
    let sedParam = {};

    if (basicData?.onRowClick) {
        row.id = row._id;
        sedParam._id = basicData._id;
    }
    else {
        if (basicData?.parameters?.length > 0) {
            basicData.parameters.map(p => {
                for (const key in row) {
                    if (key.toLowerCase() === p.toLowerCase()) {
                        sedParam[p] = row[key];
                        break;
                    }
                }
                return row[p] + '';
            });
        }

        if (basicData?.tableName === 'items') {
            sedParam.searchItem = [{
                'image': row.image,
                'articleNumber': row.articleNumber,
                'name': row.name,
                '_id': row._id
            }];
        }
        else if (basicData?.tableName === 'alerts') {
            sedParam._id = row.item;
            sedParam.searchItem = [{
                'name': row.itemName,
                '_id': row.item
            }];
        }
        else if (basicData?.tableName === 'deviations'
            || basicData?.tableName === 'results') {
            sedParam._id = row.itemId;
        }
    }

    if (headerParam !== undefined && headerParam.length > 0) {
        sedParam.from = headerParam.from;
        sedParam.to = headerParam.to;
    }
    const method = basicData?.method || 'GET';
    const param = method === 'POST' || isDownloadable
        ? '?p=' + JSON.stringify(sedParam)
        : urlEncode(sedParam);
    return basicData?.baseUrl + '/' + param;

}

export function openActions(element) {
    const actionDrawer = element.currentTarget;
    const actionItems = actionDrawer.querySelector('.action__items');

    actionDrawer.classList.toggle('action__content__wrapper--active');
    actionItems.style.right = actionDrawer.clientWidth + 'px';
}

function buildActions(actions, tableName, params, headerParam) {
    return actions.map(basicData => {
        const isDownloadable = basicData.icon === 'download';
        basicData.tableName = tableName;
        basicData.ajax = isDownloadable
            ? true
            : basicData.ajax
                ? basicData.ajax
                : false;
        const target = basicData.target
            ? basicData.target
            : isDownloadable
                ? '_blank'
                : '';
        return <ActionIcons
            method={basicData.method !== undefined ? basicData.method : 'GET'}
            ajax={basicData.ajax !== undefined ? basicData.ajax : false}
            target={target}
            tooltip={basicData.title}
            row={params.row}
            headerParam={headerParam}
            isDownloadable={isDownloadable}
            basicData={basicData}
            href={urlBuilderForAction(basicData, params.row, headerParam, isDownloadable)}
            hidden={params.row.hidden ? params.row.hidden : false}
            className={tableName + '-' + params.row._id}
            icon={basicData.icon}/>;
    });
}


function buildCheckBoxData(data, callback) {
    for (const info of data) {
        info.callback = (e, v) => {
            return callback(e, v, info);
        };
    }

    return data;
}

export function header(allData, tableName, headerParam = [], cb = undefined) {
    let headers = allData.columns;
    let actions = allData.actions;
    let onRowClick = allData.onRowClick;
    let columns = [];
    let data = allData?.data?.[tableName];

    if (Array.isArray(data)) {
        data = clearData(data);
    }
    else {
        data = [];
    }

    //build custom fields
    switch (tableName) {
        case 'reports':
        case 'lastReports':
            data = buildReportsData(data);
            break;
        case 'alerts':
            data = buildCheckBoxData(data, cb);
            break;
        default:
            break;
    }

    for (const key in headers) {
        const headerName = headers[key].headerName;
        let width = parseFloat(headers[key].width);
        if (isNaN(width) || width === undefined) {
            width = getWidth(tableName, headers[key].field);
        }
        const columnsParam = {
            field: headers[key].field,
            headerName: headerName,
            flex: width,
            type: headers[key].type
                ? headers[key].type.toLowerCase()
                : '',
            cellClassName: tableName + '-' + headerName + ' ' + tableName + '-' + key + ' ' + tableName + 'cell'
        };

        if (headers[key].type) {
            switch (headers[key].type.toLowerCase()) {
                case 'date':
                    columnsParam.renderCell = (params) => dateRenderCell(params);
                    break;
                case 'link':
                    columnsParam.align = 'center';
                    columnsParam.headerAlign = 'center';
                    columnsParam.renderCell = (params) => linkRenderCell(params);
                    break;
                case 'percentage':
                    columnsParam.renderCell = (params) => percentageRenderCell(params);
                    columnsParam.type = 'number';
                    columnsParam.headerAlign = 'right';
                    columnsParam.cellClassName += ' alignRight';
                    columnsParam.type = headers[key].type;
                    break;
                case 'integer':
                    columnsParam.align = 'right';
                    columnsParam.headerAlign = 'right';
                    columnsParam.cellClassName += ' alignRight';
                    // columnsParam.type = 'number';
                    columnsParam.type = headers[key].type;
                    break;
                case 'array':
                    columnsParam.renderCell = (params) => arrayRenderCell(params, tableName);
                    break;
                case 'byte':
                    columnsParam.renderCell = (params) => byteRenderCell(params, tableName);
                    columnsParam.type = headers[key].type;
                    break;
                case 'image':
                    columnsParam.renderCell = (params) => imageRenderCell(params);
                    break;
                case 'custom':
                    columnsParam.renderCell = (params) => customRenderCell(params, tableName, headers[key]);
                    break;
                case 'number':
                    columnsParam.align = 'right';
                    columnsParam.headerAlign = 'right';
                    columnsParam.cellClassName += ' alignRight';
                    columnsParam.renderCell = (params) => {
                        let value = Number(params.value);
                        if (!isNaN(value)) {
                            columnsParam.align = 'right';
                            return Number(params.value).toFixed(2);
                        }
                        return params.value;
                    };
                    break;
            }
        }
        columns.push(columnsParam);
    }

    if (actions?.length) {
        columns.push({
            field: 'action',
            cellClassName: 'action-col-wrap action__cell',
            headerName: ' ',
            renderCell: (params, columns) => {
                if (actions.length > 2) {
                    return (<div
                        className={'action-col action__content__wrapper'}
                        onClick={(event) => openActions(event)}>
                        <a className={'actionIcon'}>
                            <svg className="fontSizeSmall" height="16"
                                 viewBox="0 -960 960 960" width="16">
                                <path
                                    d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/>
                            </svg>
                        </a>

                        <div className={'action__items'}>
                            {
                                buildActions(actions, tableName, params, headerParam)
                            }
                        </div>
                    </div>);
                }
                else {
                    return (<div
                        className={'action-col action__content__wrapper action__content__wrapper--active'}
                        style={{'background': 'unset'}}>
                        <div className={'action-col'}>
                            {
                                buildActions(actions, tableName, params, headerParam)
                            }
                        </div>
                    </div>);
                }
            }

        });
    }

    if (onRowClick && Object.keys(onRowClick).length > 0) {
        onRowClick['tableName'] = tableName;
        data.forEach((element, index) => {
            let basicData = {...data[index], ...onRowClick};
            basicData['onRowClick'] = true;
            data[index]['onRowClick'] = urlBuilderForAction(basicData, data[index], [], false);
        });
    }

    return {
        loading: true,
        columns: columns,
        data: data,
        onRowClick: onRowClick
    };
}

function dateRenderCell(params) {
    if (params.value === 0) {
        return ' ';
    }
    const dateTmp = new Date(params.value);
    return Moment(dateTmp).isValid()
        ? Moment(dateTmp).format('DD.MM.YYYY HH:mm')
        : params.value;
}

function linkRenderCell(params) {
    return <div className={'center-align'}>
        <a className={'grid-link'} href={params.value} target={'_blank'}>
            <HtmlTooltip placement="top"
                         title={params.value}>
                <OpenInNewIcon fontSize="small"/>
            </HtmlTooltip>
        </a>
    </div>;
}

function percentageRenderCell(params) {
    return params.value + ' %';
}

function byteRenderCell(params) {
    return byteToHumanReadable(params.value);
}

function arrayRenderCell(params, tableName) {
    switch (tableName) {
        case 'ambiguous':
            return ambiguous(params.value);
        default:
            break;
    }
}

function imageRenderCell(params) {
    return <ImageWithSkeleton className={'gridImage'} src={params.value}
                              height={'50px'} width={'50px'}/>;
}

function buildCheckBoxes(paramsRow) {
    const isChecked = paramsRow.value;
    const onChangeCallback = paramsRow.row.callback;
    return (<SwitchComponent checked={isChecked}
                             onChange={onChangeCallback}></SwitchComponent>);
}

function customRenderCell(params, tableName, header) {
    switch (tableName) {
        case 'reports':
        case 'lastReports':
            let total = params.row.total;
            let resultJson = isJsonString(params.value);
            if (!resultJson) {
                return '';
            }
            switch (header.field) {
                case 'now' :
                    return <GridBar data={params.row} total={total}
                                    toDo={resultJson.toDo}
                                    totalObj={params.row.total}
                                    doneVal={resultJson.found} doneLabel="Found"
                                    doneColor={getReportColor('doneColor')}
                                    estimatedVal={resultJson.expected}
                                    estimatedLabel="Estimated"
                                    estimatedColor="#fff"
                                    notFoundVal={resultJson.notFound}
                                    notFoundLabel="Not Found"
                                    notFoundColor={getReportColor('notfoundColor')}
                                    botProtectionVal={resultJson.botProtection}
                                    botProtectionLabel="Bot Protection"
                                    botProtectionColor={getReportColor('botProtectionColor')}
                                    othersVal={resultJson.others}
                                    othersLabel="Others"
                                    othersColor={getReportColor('otherColor')}
                                    noPriceColor={getReportColor('noPriceColor')}
                                    noPriceVal={resultJson.noPriceFound}
                                    noPriceLabel={'No Price Found'}
                                    notAvaibleColor={getReportColor('notAvailableColor')}
                                    notAvaibleVal={resultJson.notAvailable}
                                    notAvaibleLabel={'Not Available'}
                                    ambiguousVal={resultJson.ambiguous}
                                    ambiguousLabel="Ambiguous"
                                    ambiguousColor={getReportColor('ambiguousColor')}
                                    notEnoughInfoVal={resultJson.notEnoughInfo ? resultJson.notEnoughInfo : 0}
                                    notEnoughInfoColor={getReportColor('notEnoughInfo')}
                                    notEnoughInfoLabel={'Not Enough Info'}
                                    isDownloadable={true}
                                    reportId={resultJson.reportId}
                    />;
                    break;
                case 'ago1interval':
                    return <GridBar data={params.row} total={resultJson.total}
                                    doneVal={resultJson.found}
                                    doneLabel="Found"
                                    totalObj={resultJson.totalObj}
                                    toDo={resultJson.toDo}
                                    doneColor={getReportColor('doneColor')}
                                    errorVal={resultJson.error}
                                    errorLabel="Error" errorColor="#fa3f3f"
                                    notFoundVal={resultJson.notFound}
                                    notFoundLabel="Not found"
                                    notFoundColor={getReportColor('notfoundColor')}
                                    botProtectionVal={resultJson.botProtection}
                                    botProtectionLabel="Bot Protection"
                                    botProtectionColor={getReportColor('botProtectionColor')}
                                    othersVal={resultJson.others}
                                    othersLabel="Others"
                                    othersColor={getReportColor('otherColor')}
                                    ambiguousVal={resultJson.ambiguous}
                                    ambiguousLabel="Ambiguous"
                                    ambiguousColor={getReportColor('ambiguousColor')}
                                    noPriceColor={getReportColor('noPriceColor')}
                                    noPriceVal={resultJson.noPriceFound}
                                    noPriceLabel={'No Price Found'}
                                    notAvaibleColor={getReportColor('notAvailableColor')}
                                    notAvaibleVal={resultJson.notAvailable}
                                    notAvaibleLabel={'Not Available'}
                                    notEnoughInfoVal={resultJson.notEnoughInfo}
                                    notEnoughInfoColor={getReportColor('notEnoughInfo')}
                                    notEnoughInfoLabel={'Not Enough Info'}
                                    isDownloadable={true}
                                    reportId={resultJson.reportId}
                    />;
                    break;
                case 'ago2interval':
                    return <GridBar data={params.row} total={resultJson.total}
                                    doneVal={resultJson.found}
                                    doneLabel="Found"
                                    totalObj={resultJson.totalObj}
                                    toDo={resultJson.toDo}
                                    doneColor={getReportColor('doneColor')}
                                    errorVal={resultJson.error}
                                    errorLabel="Error" errorColor="#fa3f3f"
                                    notFoundVal={resultJson.notFound}
                                    notFoundLabel="Not found"
                                    notFoundColor={getReportColor('notfoundColor')}
                                    botProtectionVal={resultJson.botProtection}
                                    botProtectionLabel="Bot Protection"
                                    botProtectionColor={getReportColor('botProtectionColor')}
                                    othersVal={resultJson.others}
                                    othersLabel="Others"
                                    othersColor={getReportColor('otherColor')}
                                    ambiguousVal={resultJson.ambiguous}
                                    ambiguousLabel="Ambiguous"
                                    ambiguousColor={getReportColor('ambiguousColor')}
                                    isDownloadable={true}
                                    noPriceColor={getReportColor('noPriceColor')}
                                    noPriceVal={resultJson.noPriceFound}
                                    noPriceLabel={'No Price Found'}
                                    notAvaibleColor={getReportColor('notAvailableColor')}
                                    notAvaibleVal={resultJson.notAvailable}
                                    notAvaibleLabel={'Not Available'}
                                    notEnoughInfoVal={resultJson.notEnoughInfo}
                                    notEnoughInfoColor={getReportColor('notEnoughInfo')}
                                    notEnoughInfoLabel={'Not Enough Info'}
                                    reportId={resultJson.reportId}
                    />;
                    break;
            }

            break;
        case 'servers':
            return buildServerChip(JSON.stringify(params.row[header.field]));
        case 'alerts':
            return buildCheckBoxes(params);
        default:
            break;
    }
}

function buildServerChip(activeProcess) {
    const result = JSON.parse(activeProcess);
    let retTrue = [], retFalse = [], ret = [];
    for (const key in result) {
        if (result[key]) {
            retTrue.push(
                <Tooltip arrow
                         title={JSON.stringify(result[key], null, 2)}>
                    <Chip
                        className={'servers-chip good'} color="secondary"
                        label={key}/>
                </Tooltip>
            );
        }
        else {
            retFalse.push(<Chip className={'servers-chip bad'} label={key}/>);
        }
    }
    ret = retFalse.concat(retTrue);
    return React.createElement(React.Fragment, {}, ...ret);
}

export function urlEncode(param) {
    return btoa(encodeURIComponent(JSON.stringify(param)));
}

function isJsonString(str) {
    try {
        return JSON.parse(str);
    }
    catch (e) {
        return str;
    }
}

export function urlDecode(param) {
    try {
        return JSON.parse(decodeURIComponent(window.atob(decodeURIComponent(param.decompress()))));
    }
    catch (error) {
        showError(error);
        return false;
    }
}

export function doHeaderType(val, type) {
    switch (type.toLowerCase()) {
        case 'image':
            return <ImageWithSkeleton
                src={val || noImageSmall}/>;
            return <img src={val ? val : noImageSmall}/>;
        case 'date':
            const dateTmp = new Date(val);
            return Moment(dateTmp).isValid()
                ? Moment(dateTmp).format('DD.MM.YYYY HH:mm')
                : val;
        case 'link':
            return <span className="linkPlace">{val}</span>;
        case 'htmlstring':
        case 'string':
        default:
            return <div dangerouslySetInnerHTML={{__html: val}}/>;
    }
}

function ambiguous(params) {
    let result = params;
    let ret = [];
    for (const [key, value] of Object.entries(result)) {
        switch (key) {
            case 'good' :
                ret.push(<Tooltip arrow title={'Good'} placement={'top'}><Chip
                    color="secondary"
                    label={value.toString()}/></Tooltip>);
                break;
            case 'middle':
                ret.push(<Tooltip arrow title={'Middle'} placement={'top'}><Chip
                    color="primary"
                    label={value.toString()}/></Tooltip>);
                break;
            case 'bad':
                ret.push(<Tooltip arrow title={'Bad'} placement={'top'}><Chip
                    label={value.toString()}/></Tooltip>);
                break;
        }
    }
    return React.createElement(React.Fragment, {}, ...ret);
}

function clearData(data) {
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id)
            data[i].id = i;
    }
    return data;
}

/////////Reports
function buildReportsData(data) {
    //ToDo Clear Code (redundancy)

    for (let i = 0; i < data.length; i++) {
        data[i].results = JSON.stringify({
            'total': data[i].total,
            'totalObj': data[i].now.total,
            'expected': data[i].now.expected ? data[i].now.expected : null,
            'found': data[i].now.found,
            'botProtection': data[i].now.botProtection,
            'notFound': data[i].now.notFound,
            'reportId': data[i].now.reportId,
            'toDo': data[i].now.toDo,
            'others': data[i].now.hasOwnProperty('others')
                ? data[i].now.others
                : null,
            'notEnoughInfo': data[i].now.hasOwnProperty('notEnoughInfo')
                ? data[i].now.notEnoughInfo
                : 0,
            'ambiguous': data[i].now.hasOwnProperty('ambiguous')
                ? data[i].now.ambiguous
                : 0,
            'noPriceFound': data[i].now.hasOwnProperty('noPriceFound')
                ? data[i].now.noPriceFound
                : null,
            'notAvailable': data[i].now.hasOwnProperty('notAvailable')
                ? data[i].now.notAvailable
                : 0
        });

        if (data[i].hasOwnProperty('ago1interval')) {
            data[i].ago1interval = JSON.stringify({
                'total': data[i].total,
                'totalObj': data[i].ago1interval.total,
                'error': data[i].ago1interval.error,
                'found': data[i].ago1interval.found,
                'reportId': data[i].ago1interval.reportId,
                'toDo': data[i].ago1interval.toDo,
                'botProtection': data[i].ago1interval.botProtection,
                'notFound': data[i].ago1interval.notFound,
                'others': data[i].ago1interval.hasOwnProperty('others')
                    ? data[i].ago1interval.others
                    : null,
                'notEnoughInfo': data[i].ago1interval.hasOwnProperty('notEnoughInfo')
                    ? data[i].ago1interval.notEnoughInfo
                    : 0,
                'ambiguous': data[i].ago1interval.hasOwnProperty('ambiguous')
                    ? data[i].ago1interval.ambiguous
                    : 0,
                'noPriceFound': data[i].ago1interval.hasOwnProperty('noPriceFound')
                    ? data[i].ago1interval.noPriceFound
                    : null,
                'notAvailable': data[i].ago1interval.hasOwnProperty('notAvailable')
                    ? data[i].ago1interval.notAvailable
                    : 0
            });
        }

        if (data[i].hasOwnProperty('ago2interval')) {
            data[i].ago2interval = JSON.stringify({
                'total': data[i].total,
                'totalObj': data[i].ago2interval.total,
                'error': data[i].ago2interval.error,
                'found': data[i].ago2interval.found,
                'toDo': data[i].ago2interval.toDo,
                'reportId': data[i].ago2interval.reportId,
                'botProtection': data[i].ago2interval.botProtection,
                'notFound': data[i].ago2interval.notFound,
                'others': data[i].ago2interval.hasOwnProperty('others')
                    ? data[i].ago2interval.others
                    : null,
                'notEnoughInfo': data[i].ago2interval.hasOwnProperty('notEnoughInfo')
                    ? data[i].ago2interval.notEnoughInfo
                    : 0,
                'ambiguous': data[i].ago2interval.hasOwnProperty('ambiguous')
                    ? data[i].ago2interval.ambiguous
                    : 0,
                'noPriceFound': data[i].ago2interval.hasOwnProperty('noPriceFound')
                    ? data[i].ago2interval.noPriceFound
                    : null,
                'notAvailable': data[i].ago2interval.hasOwnProperty('notAvailable')
                    ? data[i].ago2interval.notAvailable
                    : 0
            });
        }
    }
    return data;
}

function getReportColor(name) {
    const color = {
        notfoundColor: '#FAAB3F',
        doneColor: '#2FD08B',
        botProtectionColor: '#8F8F8F',
        otherColor: '#FA3F3F',
        ambiguousColor: '#FFA7A7',
        notAvailableColor: '#7EF2C0',
        noPriceColor: '#41E29D',
        notEnoughInfo: '#f87f2f'
    };
    return color[name];
}

/////////End Reports

export function buildNavigation(data) {
    data = data.navigator;
    let navigations = [];
    let idLink = 11030;
    Object.keys(data)
        .forEach(function (key) {
            if (key !== 'LOGOUT') {
                let subMenu = [];
                let link = data[key].href + '/list/';

                if (key === 'HOME') {
                    link = '/home/dashboard/';
                }

                const child = data[key].children;
                if (child) {

                    child.forEach((item, index) => {
                            const childItem = child[index];
                            let href = childItem.href;
                            if (!href.endsWith('list/')) {
                                href = href + '/';
                            }
                            subMenu.push(
                                {
                                    name: childItem.content,
                                    link: href,
                                    id: idLink++
                                }
                            );
                        }
                    );

                }
                idLink++;
                navigations.push({
                    name: data[key].content,
                    icon: iconParse(data[key].icon),
                    id: idLink,
                    link: link,
                    alwaysexpand: false,
                    menus: subMenu
                });
            }
        });

    return navigations;
}

//////////////End Reports

function iconParse(name) {
    const iconsList = {
        'iconObserver': iconObserver,
        'iconHome': iconHome,
        'iconPages': iconPages,
        'iconProxies': iconProxies,
        'iconUsers': iconUsers,
        'iconServers': iconServers,
        'iconOrders': iconOrders,
        'iconResults': iconResults,
        'iconReports': iconReports,
        'iconProducts': iconProduct
    };

    return iconsList[name];
}

function getWidth(tableName, columnName) {
    const mapWidth = {
        'servers': {
            'activeProcesses': 4,
            'queueTaskUpdate': 1,
            'scheduledInProgress': 1,
            'lastStartedTask': 1,
            'name': 1
        },
        'history': {'action': 0.5},
        'items': {
            'image': 0.5,
            'name': 2,
            'articlenumber': 1
            // 'action': 1
        },
        'conflicts': {
            'image': 0.5,
            'name': 1,
            'articlenumber': 1
        },
        'orders': {
            'alias': 2,
            'startdate': 1,
            'enddate': 1,
            'sites': 1,
            'items': 1,
            'action': 0.5
        },
        'results': {
            'date': 0.8,
            'itemNr': 1,
            'name': 1.5,
            'price': 0.6,
            'currency': 0.5,
            'site': 0.8,
            'url': 0.4,
            'isBuyBox': 0.2,
            'isPrime': 0.2,
            'isIdent': 0.2,
            'merchant': 0.5,
            'author': 0.5
        },
        'reports': {
            'orderName': 4,
            'shopName': 1,
            'interval': 0.8,
            now: 1.5,
            ago1interval: 1.5,
            ago2interval: 1.5
        },
        'lastReports': {
            'total': 0.7,
            'interval': 0.5,
            'createdAt': 0.7
        },
        'deviations': {
            itemName: 1,
            'date': 0.5,
            'reference': 0.5,
            'currency': 0.3,
            'foundprice': 0.5,
            'difference': 0.5,
            'site': 0.5,
            'merchant': 0.5,
            'url': 0.3
        },
        'ambiguous': {
            'image': 1,
            'articlenumber': 2,
            'name': 3,
            'shopname': 1,
            'ratings': 2
            // 'action': 1
        }
    };

    try {
        return mapWidth[tableName]?.[columnName.toLowerCase()]
            ? mapWidth[tableName][columnName.toLowerCase()]
            : 1;
    }
    catch {
        return 1;
    }
}

export function findObjectByPropertyValue(array, propertyName, searchValue) {
    if (array) {
        const foundObject = array.find(item => item[propertyName] === searchValue);
        return foundObject !== undefined ? foundObject : false;
    }
    return false;
}

function byteToHumanReadable(byteNr) {
    if (!byteNr) {
        return 'N/A';
    }
    const mb = byteNr / 1024 / 1024;
    if (mb >= 1) {
        return mb.toFixed(2) + ' MiB';
    }
    const kb = byteNr / 1024;
    return kb.toFixed(2) + ' KiB';
}

export function showError(...parameter) {
    if (debugMode)
        console.log(parameter);
}

export function showNetworkError(obj, err) {
    if (err && err !== 'AbortError') {
        toast.error(err);
    }

    obj?.setState({
        data: [],
        columns: [],
        loading: false
    });
}

export function getStartEndDate(type) {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0, 0, 0);

    switch (type) {
        case 'start':
            return new Date(today);
        case 'end':
            const hoursNow = new Date().getHours();
            if (hoursNow !== 0) {
                today.setHours(hoursNow);
            }
            else {
                today.setHours(hoursNow + 1);
            }
            return new Date(today);
        default:
            return today;
    }
}

String.prototype.compress = function (asArray) {
    'use strict';
    // Build the dictionary.
    asArray = (asArray === true);
    var i,
        dictionary = {},
        uncompressed = this,
        c,
        wc,
        w = '',
        result = [],
        ASCII = '',
        dictSize = 256;
    for (i = 0; i < 256; i += 1) {
        dictionary[String.fromCharCode(i)] = i;
    }

    for (i = 0; i < uncompressed.length; i += 1) {
        c = uncompressed.charAt(i);
        wc = w + c;
        //Do not use dictionary[wc] because javascript arrays
        //will return values for array['pop'], array['push'] etc
        // if (dictionary[wc]) {
        if (dictionary.hasOwnProperty(wc)) {
            w = wc;
        }
        else {
            result.push(dictionary[w]);
            ASCII += String.fromCharCode(dictionary[w]);
            // Add wc to the dictionary.
            dictionary[wc] = dictSize++;
            w = String(c);
        }
    }

    // Output the code for w.
    if (w !== '') {
        result.push(dictionary[w]);
        ASCII += String.fromCharCode(dictionary[w]);
    }
    return asArray ? result : ASCII;
};

String.prototype.decompress = function () {
    'use strict';
    // Build the dictionary.
    var i, tmp = [],
        dictionary = [],
        compressed = this,
        w,
        result,
        k,
        entry = '',
        dictSize = 256;
    for (i = 0; i < 256; i += 1) {
        dictionary[i] = String.fromCharCode(i);
    }

    if (compressed && typeof compressed === 'string') {
        // convert string into Array.
        for (i = 0; i < compressed.length; i += 1) {
            tmp.push(compressed[i].charCodeAt(0));
        }
        compressed = tmp;
        tmp = null;
    }

    w = String.fromCharCode(compressed[0]);
    result = w;
    for (i = 1; i < compressed.length; i += 1) {
        k = compressed[i];
        if (dictionary[k]) {
            entry = dictionary[k];
        }
        else {
            if (k === dictSize) {
                entry = w + w.charAt(0);
            }
            else {
                return null;
            }
        }

        result += entry;

        // Add w+entry[0] to the dictionary.
        dictionary[dictSize++] = w + entry.charAt(0);

        w = entry;
    }
    return result.replaceAll('%3D', '=');
};
