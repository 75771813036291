import React, {useState, useEffect} from 'react';
import {ajaxRequest} from 'data_access/data';

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
        useEffect(() => {
            ajaxRequest('/meta.json')
                .then((meta) => {
                    let currentVersionDate = '';
                    const latestVersionDate = meta.buildDate;
                    currentVersionDate = localStorage.getItem('buildDate');

                    if (!currentVersionDate) {
                        localStorage.setItem('buildDate', latestVersionDate);
                        currentVersionDate = latestVersionDate;
                    }

                    if (latestVersionDate > currentVersionDate) {
                        setIsLatestBuildDate(false);
                        localStorage.setItem('buildDate', latestVersionDate);
                        refreshCacheAndReload();
                    }
                    else {
                        setIsLatestBuildDate(true);
                    }
                });
        }, []);

        const refreshCacheAndReload = () => {
            const caches = window.caches;
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys()
                    .then((names) => {
                        for (const name of names) {
                            caches.delete(name);
                        }
                    });
            }
            // delete browser cache and hard reload
            window.location.reload();
        };
        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} /> : null}
            </React.Fragment>
        );
    }

    return ClearCacheComponent;
}

export default withClearCache;
