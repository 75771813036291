import React from 'react';
import './style.scss';
import {ClickAwayListener, Popper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import LoadingButton from '../LoadingButton';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
        fontSize: '10px'
    },
    paper: {
        backgroundColor: '#4D49B0',
        padding: theme.spacing(1),
        color: '#fff',
        borderRadius: '10px',
        fontSize: '12px'
    }
}));
const ToolTipGrid = (props) => {
    const {
        name,
        type,
        val,
        color,
        callBack,
        data,
        isDownloadable,
        reportId
    } = props;
    return (
        <div className={'bar-container'}>
            <div className={'dot'} style={{backgroundColor: color}}></div>
            <span className={'bar-text'}>{name} : <span>{val}</span></span>
            {isDownloadable
                ? <span className={'open-icon'} onClick={callBack}>
                {
                    type !== false
                        ? <LoadingButton
                            fullWidth
                            loading={false}
                            fileName={data.orderName + '_' + data.shopName + '_' + type + '_' + Date.now() + '.csv'}
                            columns={{}}
                            color='secondary'
                            id='submit-login'
                            api={'itemsReport'}
                            stream={false}
                            tableName={'items'}
                            reportId={reportId}
                            parameter={{
                                orderId: data.orderId,
                                siteName: data.shopName,
                                type: type,
                                reportId: reportId
                            }}
                            noButton={true}
                        />
                        : ''
                }
            </span>
                : ''
            }
        </div>
    );
};

const GridBar = (props) => {
    let {
        data,
        doneVal,
        estimatedVal,
        notFoundVal,
        othersVal,
        botProtectionVal,
        noPriceVal,
        notAvaibleVal,
        notEnoughInfoVal,
        notEnoughInfoColor,
        notEnoughInfoLabel,
        ambiguousVal,
        doneColor,
        noPriceColor,
        notAvaibleColor,
        estimatedColor,
        notFoundColor,
        othersColor,
        botProtectionColor,
        ambiguousColor,
        doneLabel,
        estimatedLabel,
        notFoundLabel,
        othersLabel,
        noPriceLabel,
        notAvaibleLabel,
        botProtectionLabel,
        ambiguousLabel,
        total,
        totalObj,
        isDownloadable,
        reportId,
        toDo,
        progress
    } = props;

    function percentage(partialValue, totalValue) {
        if (totalValue === 0) {
            return 0.01;
        }
        if (typeof partialValue === 'undefined' || partialValue === null) {
            partialValue = 0;
        }
        let perc = (100 * partialValue) / totalValue;

        return perc == 0 ? 0.01 : perc;
    }

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const downloadReport = (event) => {

    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    let doneValp = percentage(doneVal, totalObj);
    let othersValp = percentage(othersVal, totalObj);
    let botProtectionValp = percentage(botProtectionVal, totalObj);
    let notEnoughInfoValp = percentage(notEnoughInfoVal, totalObj);
    let notFoundValp = percentage(notFoundVal, totalObj);
    let noPriceValp = percentage(noPriceVal, totalObj);
    let notAvaibleValp = percentage(notAvaibleVal, totalObj);
    let ambiguousValp = percentage(ambiguousVal, totalObj);

    progress = totalObj - toDo;
    noPriceValp = doneValp + noPriceValp;
    notAvaibleValp = noPriceValp + notAvaibleValp;
    notEnoughInfoValp = notAvaibleValp + notEnoughInfoValp;
    notFoundValp = notEnoughInfoValp + notFoundValp;
    ambiguousValp = notFoundValp + ambiguousValp;
    botProtectionValp = ambiguousValp + botProtectionValp;
    othersValp = botProtectionValp + othersValp;

    //-2 margin
    let estimatedValp = percentage(estimatedVal, totalObj) - 2;
    if (estimatedValp <= 0) {
        // 0.1 margin
        estimatedValp = 0.1;
    }

    let idP = '';
    let bg = [];
    let values = [];
    let diveContainer = [];// doneLabel + ' : ' + doneVal + '<br>' + errorLabel + ' : ' + estimatedVal + '<br>' + estimatedLabel + ' : ' + estimatedVal + '<br>';
    function prePairBarStyle(name, color, val, percentage, type = null, reportId) {
        if (typeof name !== 'undefined' && name !== null) {
            bg.push('linear-gradient(' + color + ',' + color + ')');
            values.push(percentage + '% 100%');
            diveContainer.push(
                <ToolTipGrid isDownloadable={isDownloadable} data={data}
                             type={type != null ? type : false} name={name}
                             val={val} color={color} reportId={reportId}
                             callBack={downloadReport}/>
            );
            idP += name + '.' + val;
        }
    }

    let showEstimate = 'none';
    if (typeof estimatedLabel !== 'undefined' && estimatedLabel !== null && totalObj > 0) {
        showEstimate = 'block';
        diveContainer.push(
            <ToolTipGrid type={false} name={estimatedLabel} val={estimatedVal}
                         color={estimatedColor}
                         callBack={downloadReport.bind(this)}/>
        );
        idP += estimatedLabel + '.' + estimatedVal;
    }
    prePairBarStyle(doneLabel, doneColor, doneVal, doneValp, 0, reportId);
    prePairBarStyle(noPriceLabel, noPriceColor, noPriceVal, noPriceValp, 5, reportId);
    prePairBarStyle(notAvaibleLabel, notAvaibleColor, notAvaibleVal, notAvaibleValp, 6, reportId);
    prePairBarStyle(notEnoughInfoLabel, notEnoughInfoColor, notEnoughInfoVal, notEnoughInfoValp, 8, reportId);
    prePairBarStyle(notFoundLabel, notFoundColor, notFoundVal, notFoundValp, 1, reportId);
    prePairBarStyle(ambiguousLabel, ambiguousColor, ambiguousVal, ambiguousValp, 3, reportId);
    prePairBarStyle(botProtectionLabel, botProtectionColor, botProtectionVal, botProtectionValp, 2, reportId);

    if (othersVal !== null && othersVal !== '' && othersVal !== undefined) {
        prePairBarStyle(othersLabel, othersColor, othersVal, othersValp, 4, reportId);
    }

    const progressStyle = {
        width: '100%',
        backgroundColor: '#dbf3fd',
        backgroundImage: bg.join(),
        backgroundSize: values.join(),
        backgroundRepeat: 'no-repeat',
        position: 'absolute'
    };
    const estimate = {
        width: '5px',
        backgroundColor: estimatedColor,
        backgroundRepeat: 'no-repeat',
        zIndex: '10',
        left: estimatedValp + '%',
        display: showEstimate,
        position: 'absolute'
    };

    return (
        <ClickAwayListener onClickAway={handlePopoverClose}>
            <div className="gridContainer">
                <div
                    className="gridBar" style={estimate}>
                </div>

                <div aria-owns={open ? {idP} : undefined}
                     aria-haspopup="true"
                     onClick={handlePopoverOpen}
                     className="gridBar" style={progressStyle}>
                </div>
                <Popper style={{zIndex: 10000}} id={idP} open={open}
                        anchorEl={anchorEl}>
                    <div className={'gridToolTip'}>
                        Progress <span>{progress}</span> of <span>{totalObj} ({((progress / totalObj) * 100).toFixed(2)}%)</span>
                        {
                            diveContainer.map((component, index) => (
                                <React.Fragment key={index}>
                                    {component}
                                </React.Fragment>
                            ))}
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    )
        ;
};
export default GridBar;


