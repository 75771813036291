import * as React from 'react';
import {withStyles} from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import {Component} from "react";

const styles = {
    tooltip: {
        backgroundColor: '#4D49B0',
        color: '#fff',
        maxWidth: 220,
        lineHeight: '15px',
        fontSize: '10px'
    }
};

class HtmlTooltip extends Component {
    render() {
        return <Tooltip {...this.props}>
            {this.props.children}
        </Tooltip>
    }
}

export default withStyles(styles, {withTheme: true})(HtmlTooltip);
