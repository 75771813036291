import {ajaxRequest} from './data';
import {storageGetItem, storageSetItem} from '../utils/storage';
import {showError, showNetworkError} from '../utils/display';

export async function getUserProfile() {
    return await ajaxRequest('/ownProfile');
}

export async function changePassword(oldPass, newPass) {
    return ajaxRequest('/changePassword?oldPassword=' + oldPass + '&newPassword=' + newPass, null, 'POST');
}

export function prepairUserProfile(allData) {
    let headers = allData.columns;
    let result = [];
    let data = allData.data.profile;
    let name = ' ', lastName = ' ', email = '';
    for (const key in headers) {
        const headerName = headers[key].headerName;
        const field = headers[key].field;
        const value = data[headers[key].field];
        if (field.toLowerCase() === 'email') {
            email = value;
        }
        if (field.toLowerCase() === 'name') {
            name = value;
        }
        if (field.toLowerCase() === 'lastname') {
            lastName = value;
        }
        result.push({
            label: headerName,
            value
        });
    }
    return {
        data: result,
        fullName: name + ' ' + lastName,
        email: email
    };
}

export async function getUserInfo() {
    let userInfo = await storageGetItem('userInfo');
    if (!userInfo) {
        const data = await getUserProfile();
        if (!data.error) {
            userInfo = prepairUserProfile(data);
            storageSetItem('userInfo', JSON.stringify(userInfo));
        }
        else if (data.error) {
            showNetworkError(this, data.error);
        }
    }
    return JSON.parse(userInfo);
}

export async function setUserInfo() {
    const data = await getUserProfile();
    if (!data.error) {
        const userInfo = prepairUserProfile(data);
        storageSetItem('userInfo', JSON.stringify(userInfo));
    }
    else if (data.error) {
        showNetworkError(this, data.error);
    }
}

export async function createUserGet() {
    const data = await ajaxRequest('/createUser', [], 'GET');
    return data;
}

export async function createUserPost(allData) {
    const data = await ajaxRequest('/createUser?p=' + allData, [], 'POST');
    return data;
}

export function getDefaultExtension() {
    try {
        return JSON.parse(storageGetItem('user')).extension;
    }
    catch (e) {
        return 'csv';
    }
}

export function setLocalDefaultExtension(ext) {
    let userInfo = JSON.parse(storageGetItem('user'));
    if (userInfo) {
        userInfo.extension = ext;
        storageSetItem('user', JSON.stringify(userInfo));
    }
}

export async function getDefaultExtensionList() {
    return await ajaxRequest('/component?c=extensions');
}

export async function setDefaultExtensionList(ext) {
    if (ext) {
        return await ajaxRequest('/extension?p=' + ext, null, 'PUT');
    }
    else {
        showNetworkError('Extension is empty');
    }
}
