export function storageSetItem(key, value) {
    storageDeleteItem(key);
    localStorage.setItem(key, window.btoa(value));
}
export function storageGetItem(key) {
    if(localStorage.getItem(key)) {
        return window.atob(localStorage.getItem(key));
    }
    else {
        return false;
    }
}
export function storageDeleteItem(key) {
    localStorage.removeItem(key);
}
