import React, {Component} from 'react';
import Moment from 'moment';
import './style.scss';
import GetAppIcon from '@mui/icons-material/GetApp';
import {ajaxRequest, ajaxRequestStream} from 'data_access/data';
import LaunchIcon from '@mui/icons-material/Launch';
import {CircularProgress} from '@mui/material';
import {toast} from 'react-toastify';
import {showNetworkError} from 'utils/display';
import * as ExcelJS from 'exceljs';
import {getDefaultExtension} from 'data_access/users';
import {saveAs} from 'file-saver';

function downloadCsv(csv, filename) {
    // CSV FILE
    const csvFile = new Blob([csv], {type: 'text/csv'});
    // Download link
    const downloadLink = document.createElement('a');
    // File name
    downloadLink.download = filename;
    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);
    // Make sure that the link is not displayed
    downloadLink.style.display = 'none';
    // Add the link to your DOM
    document.body.appendChild(downloadLink);
    // Download it
    return downloadLink;
}

function collectHeaders(jsonRows, headersMap, exclude, type = 'csv') {
    let headers = {};

    for (const row of jsonRows) {
        headers = Object.assign(headers, row);
    }

    return Object.keys(headers)
        .map(function (element, index) {
            if (headersMap) {
                if (!headersMap[index]) {
                    return false;
                }
                if (!exclude[headersMap[index].field]) {
                    if (type === 'xlsx') {
                        return {
                            header: headersMap[index].headerName,
                            key: headersMap[index].field,
                            type: headersMap[index].type
                        };
                    }
                    return headersMap[index].headerName;
                }
                else {
                    return false;
                }
            }
            else {
                return element;
            }
        })
        .filter(function byDefined(el) {
            return !!el;
        });
}

function sleep(ms) {
    return new Promise(function (resolve, reject) {
        setTimeout(resolve, ms);
    });
}

function convertType(variable, type) {
    switch (type.toLowerCase()) {
        case 'number' :
        case 'integer':
            return Number(variable);
        case 'date':
            const dateTmp = Moment(new Date(variable)).format('YYYY-MM-DD HH:mm');
            return dateTmp ? dateTmp : variable;
        //return Moment(new Date(variable)).format('DD/MM/YYYY HH:mm') ;
        case 'string':
            return variable;
        case 'percentage':
            return parseFloat(variable) / 100;
        case 'image':
            if (variable) {
                return variable;
            }
            else {
                return 'No image found';
            }
        default:
            return variable;
    }
}

function mapExcludeArray(excludeArray) {
    const excludeMap = {};
    for (const keyToExclude of excludeArray) {
        excludeMap[keyToExclude] = true;
    }
    return excludeMap;
}

async function jsonToXlsxDownload(jsonRows, headersMap, filename, callback, exclude = []) {
    if (jsonRows?.length) {
        const excludeMap = mapExcludeArray(exclude);
        const headers = collectHeaders(jsonRows, headersMap, excludeMap, 'xlsx');
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('My Sheet');

        const columns = [];
        headers.forEach((item) => {
            columns.push(item);
        });

        worksheet.columns = headers;
        let cnt = 0;
        jsonRows.forEach((item) => {
            if (callback && typeof callback === 'function') {
                cnt += 1;
                callback(cnt, jsonRows.length);
            }
            const row = {};
            const containBool = [];
            const containPrice = [];
            const containPercent = [];
            headers.forEach((head) => {
                row[head.key] = convertType(item[head.key], head.type);
                if (head.type.toLowerCase() === 'boolean') {
                    containBool.push(head.key);
                }
                else if (head.type.toLowerCase() === 'number') {
                    // Number are doubles
                    // For integer we expect "Integer"
                    containPrice.push(head.key);
                }
                else if (head.type.toLowerCase() === 'percentage') {
                    containPercent.push(head.key);
                }
            });

            worksheet.addRow(row); //add row to active worksheet

            if (containBool.length > 0
                || containPrice.length > 0
                || containPercent.length > 0) {
                worksheet.lastRow
                    .eachCell((cell, colNumber) => {
                        if (containBool.indexOf(worksheet.getColumn(colNumber).key) !== -1) {
                            if (cell.value === 'true') { // In the CSV it is as a string, not a bool
                                cell.value = '⬤';
                                cell.font = {color: {argb: 'FF31D08A'}};
                            }
                            else {
                                cell.value = '⬤ ';
                                cell.font = {color: {argb: 'FFfb403f'}};
                            }
                        }
                        else if (containPrice.indexOf(worksheet.getColumn(colNumber).key) !== -1) {
                            cell.numFmt = '#,##0.00';
                        }
                        else if (containPercent.indexOf(worksheet.getColumn(colNumber).key) !== -1) {
                            cell.numFmt = '0.00%';
                        }
                    });
            }
        });

        //styling
        const row = worksheet.getRow(1);
        row.eachCell(function (cell) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFBA00D6'}
            };
            cell.font = {
                name: 'Calibri',
                size: 14,
                color: {argb: 'FFFFFFFF'}
            };
        });
        worksheet.columns
            .forEach(function (column) {
                let dataMax = 0;
                column.eachCell({includeEmpty: true}, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                    if (dataMax <= (column.header.length + 2)) {
                        if (column.width <= dataMax) {
                            column.width = column.header.length + 10;
                        }
                    }
                    else {
                        column.width = dataMax + 10;
                    }
                    dataMax = 0;
                });
            });

        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, filename);
    }
    else {
        toast.error('Nothing to download');
        return false;
    }
}

async function jsonToCsvDownload(jsonRows, headersMap, filename, callback, exclude = []) {
    const CRLF = '\n';
    const SEPARATOR = ';';
    if (jsonRows?.length) {
        const excludeMap = mapExcludeArray(exclude);
        const headers = collectHeaders(jsonRows, headersMap, excludeMap);
        const firstCsvLine = headers
            .map((header) => {
                return '"' + header + '"';
            })
            .join(SEPARATOR);

        let finalStringContents = firstCsvLine + CRLF;
        for (let j = 0; j < jsonRows.length; j++) {
            const row = jsonRows[j];
            for (let i = 0; i < headers.length; i++) {
                const field = (headersMap)
                    ? headersMap[i].field
                    : headers[i];
                if (!excludeMap[field]) {
                    const type = (headersMap && headersMap[i].type)
                        ? headersMap[i].type
                        : 'String';
                    const value = convertType(row[field], type);
                    if (value === null && typeof value !== 'boolean') {
                        finalStringContents += '""';
                    }
                    else {
                        finalStringContents += '"' + value + '"';
                    }
                    finalStringContents += (i < headers.length - 1)
                        ? SEPARATOR
                        : '';
                }
            }
            finalStringContents += CRLF;
            if (callback && typeof callback === 'function') {
                await callback(j + 1, jsonRows.length);
            }
        }
        // Download CSV
        return downloadCsv(finalStringContents, filename);
    }
    else {
        toast.error('Nothing to download');
        return false;
    }
}

const defaultLabel = 'Export Data';
const defaultBG = '#7A2FB7';

class LoadingButton extends Component {
    state = {
        progress: 0,
        columns: [],
        data: [],
        exclude: ['action'],
        labelValue: defaultLabel,
        opacity: 0,
        loading: false,
        containerBG: defaultBG,
        total: 0,
        fileName: ''
    };

    constructor(props) {
        super(props);
        this.state.exclude = (props.exclude || []).concat(this.state.exclude);
    }

    renderProgress = async(value, total) => {
        const percentage = total
            ? (value / total).toFixed(2) * 100
            : 100;
        const progressValue = Math.min(100, parseInt(percentage + '')) + '%';

        this.setState({
            loading: true,
            containerBG: '#cdcdfa',
            progress: progressValue,
            labelValue: 'Loading ' + progressValue,
            opacity: percentage / 100
        });
    };
    showHideElements = (hideClass, showClass) => {
        let divsToShow = document.getElementsByClassName(showClass);
        let divsToHide = document.getElementsByClassName(hideClass); ///divsToHide is an array
        for (let i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = 'none';
            divsToShow[i].style.display = 'block';
        }
    };
    changeFileExtension = (fileName, extension) => {
        fileName = fileName
            ? fileName
            : 'reports';
        let pos = fileName.lastIndexOf('.');
        return fileName.substr(0, pos < 0 ? fileName.length : pos) + '.' + extension;
    };
    streamCallBack = async(objValue) => {
        if (objValue.error || objValue?.[0]?.error) {
            showNetworkError(this, objValue.error || objValue[0].error);
            return;
        }
        if (objValue.message === 'ok') {
            this.setState({
                'columns': objValue.columns,
                'total': objValue.data.total,
                'fileName': objValue.filename
            });
            return;
        }
        let tmpData = this.state.data;
        if (!Array.isArray(tmpData)) {
            tmpData = [];
        }
        if (Array.isArray(objValue)) {
            tmpData = tmpData.concat(objValue);
        }
        else if (!(objValue === 'done')) {
            tmpData.push(objValue);
        }

        if (tmpData.length === this.state.total) {
            this.setState({
                progress: 0,
                data: [],
                labelValue: defaultLabel,
                loading: false,
                containerBG: defaultBG
            });

            const defaultExt = getDefaultExtension();
            let fileName = this.state.fileName
                ? this.state.fileName
                : this.props.fileName;
            fileName = this.changeFileExtension(fileName, defaultExt);
            if (defaultExt !== 'csv') {
                let xlsxData = jsonToXlsxDownload(tmpData, this.state.columns, fileName, this.state.exclude);
            }
            else {
                const tmp = await jsonToCsvDownload(tmpData, this.state.columns, fileName, null, this.state.exclude);
                if (tmp !== false) {
                    tmp.click();
                }
            }
            this.showHideElements('loadingContainer', 'iconContainer');
            return;
        }
        else if (objValue === 'done') {
            return;
        }
        this.setState({
            data: tmpData,
            key: Date.now()
        });
        await this.renderProgress(tmpData.length, this.state.total);
    };
    onClick = async(e) => {
        if (!this.state.loading) {
            let data, columns;
            let exclude = this.state.exclude;
            if (this.props.api != null) {
                this.showHideElements('iconContainer', 'loadingContainer');
                this.setState({
                    loading: true,
                    labelValue: 'Wait Please...'
                });
                if (this.props.stream === false) {
                    //LoadingButton avoids Abort-Controller of ajaxRequest and sends request with force
                    data = await ajaxRequest('/' + this.props.api + '?p=' + JSON.stringify(this.props.parameter), null, 'GET', false, null, true);
                    columns = data.columns;
                    this.setState({fileName: data.filename});
                    if (!data.error) {
                        data = data.data[this.props.tableName];
                        this.setState({loading: false});
                    }
                    else if (data.error) {
                        showNetworkError(this, data.error);
                    }
                    return this.downloadPreparation(data, columns, this.state.fileName, this.renderProgress, exclude);
                }
                else {
                    //LoadingButton avoids Abort-Controller of ajaxRequest and sends request with force
                    return ajaxRequestStream('/' + this.props.api + '?p=' + JSON.stringify(this.props.parameter), this.streamCallBack, null, null, null, 'GET', false, true);
                }
            }
            else {
                if (this.props.exclude != null) {
                    exclude = this.props.exclude;
                }
                const defaultExt = getDefaultExtension();
                let fileName = this.state.fileName
                    ? this.state.fileName
                    : this.props.fileName;
                const newFileName = this.changeFileExtension(fileName, defaultExt);
                this.setState({fileName: newFileName});

                data = this.props.data;
                columns = this.props.columns;
                return this.downloadPreparation(data, columns, newFileName, this.renderProgress, exclude);
            }
        }
    };
    downloadPreparation = async(data, columns, fileName, renderProgress) => {
        const defaultExt = getDefaultExtension();
        fileName = this.state.fileName
            ? this.state.fileName
            : this.props.fileName;
        const newFileName = this.changeFileExtension(fileName, defaultExt);

        this.setState({fileName: newFileName});
        if (defaultExt !== 'csv') {
            await jsonToXlsxDownload(data, columns, newFileName, this.renderProgress, this.state.exclude);
        }
        else {
            const tmp = await jsonToCsvDownload(data, columns, newFileName, this.renderProgress, this.state.exclude);

            if (tmp !== false) {
                tmp.click();
            }
        }
        this.setState({
            progress: 0,
            labelValue: defaultLabel,
            loading: false,
            containerBG: defaultBG
        });
        this.showHideElements('loadingContainer', 'iconContainer');
    };

    render() {
        if (this.props.noButton) {
            return (
                <span>
                    <div>
                            <span style={{display: 'none'}}
                                  className={'loadingContainer'}><CircularProgress
                                size={15}/></span>
                            <span style={{display: 'block'}}
                                  className={'iconContainer'}><LaunchIcon
                                className={'launchIcon'} name={this.props.name}
                                onClick={this.onClick}
                                fontSize="small"/>
                            </span>
                    </div>
                </span>
            );
        }
        else {
            return (
                <div id="LoadingButton" className="container"
                     onClick={this.onClick}
                     style={{backgroundColor: this.state.containerBG}}>
                    <Icon display={this.state.loading}/>
                    <div className="label">
                        <div className="progress" style={{
                            width: this.state.progress,
                            opacity: this.state.opacity
                        }}>
                            &nbsp;
                        </div>
                        <span className="alabel">{this.state.labelValue}</span>
                    </div>
                </div>
            );
        }

    }
}

const Icon = (props) => {
    return <div className={'iconHolder'}
                style={{display: props.display ? 'none' : 'inline-block'}}>
        <GetAppIcon/>
    </div>;
};

export default LoadingButton;
