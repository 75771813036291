import React from 'react';
import Routes from 'containers/__Routes/Loadable';

import { Helmet } from 'react-helmet';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import GlobalStyle from '../../global-styles';

import './style.scss';
import withClearCache from '../../components/ClearCache/ClearCache';

const colors = {
    primary : '#7A2FB7',
    success: '#2FD18C',
    highlight: '#1DAEFF',
    warning: '#FAAB3F',
    error: '#FF0D63',
};

const theme = createTheme( {
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.highlight,
        },
        error: {
            main: colors.error,
        },
        warning: {
            main: colors.warning,
        },
        success: {
            main: colors.success,
        },
    },
    typography: {
        fontFamily: [
            'OpenSans',
            'sans-serif'
        ],
        button: {
            textTransform: 'none'
        }
    }
});

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />;
}

function MainApp(props) {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Helmet defaultTitle="Preismonitoring.de" />
            <Routes />
            <ToastContainer position="top-right" />
        </ThemeProvider>
    );
}

export default App;
