import { createGlobalStyle } from 'styled-components';
import 'font-awesome/css/font-awesome.min.css';
import 'react-circular-progressbar/dist/styles.css';
import './sass/themify-icons.scss'
import './sass/Pe-icon-7-filled.scss'
import './sass/icofont.scss'
import './sass/mixin.scss';
import './sass/style.scss';
const GlobalStyle = createGlobalStyle`
    .p-30{
        padding: 30px;
    }
`;

export default GlobalStyle;
