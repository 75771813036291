/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
// import 'file-loader?name=.htaccess!./.htaccess';
import configureStore from './configureStore';
// Import i18n messages
import {translationMessages} from 'i18n';
import ReactPiwik from 'react-piwik';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

const piwik = new ReactPiwik({
    url: 'https://matomo.tripuls.de/',
    siteId: 13
});

const trackAtConnect = false;

const root = ReactDOM.createRoot(MOUNT_NODE);
const render = messages => {
    root.render(
        <Provider store={store}>
            <LanguageProvider messages={messages}>
                <ConnectedRouter
                    history={piwik.connectToHistory(history, trackAtConnect)}>
                    <App/>
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>
    );
};
export const showHideLoading = (isShow, pl = '') => {
    if (isShow) {
        document.getElementById('loading').classList.remove('loading--hidden');
    }
    else {
        document.getElementById('loading').classList.add('loading--hidden');
    }
};

// if (module.hot) {
//     // Hot reloadable React components and translation json files
//     // modules.hot.accept does not accept dynamic dependencies,
//     // have to be constants at compile-time
//     module.hot.accept(['./i18n', 'containers/App'], () => {
//         ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//         render(translationMessages);
//     });
// }

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
    new Promise(resolve => {
        resolve(import('intl'));
    })
        .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
        .then(() => render(translationMessages))
        .catch(err => {
            throw err;
        });
}
else {
    render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not the most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
    require('@lcdp/offline-plugin/runtime').install(); // eslint-disable-line global-require
}
